class AjaxForm {
	constructor(form) {
		this.form = form;
		this.listen();
	}

	listen() {
		this.form.addEventListener("submit", event => {
			event.preventDefault();
			this.submit();
		});
	}

	submit() {
		const request = new XMLHttpRequest();
		let payload = new FormData(this.form);
		payload.append("is-ajax", "1");
		this.form.classList.add("is-loading");

		request.onreadystatechange = () => {
			if (request.status === 200 && request.readyState === 4) {
				const template = document.createElement('template');
				template.innerHTML = request.responseText;
				const newForm = template.content.firstChild;

				this.form.parentNode.replaceChild(newForm, this.form);

				this.form = newForm;
				this.listen();
			}
		};

		request.open(this.form.method.toUpperCase(), this.form.action, true);
		request.send(payload);
	}
}

const forms = [...document.querySelectorAll("form.ajax-form")];

forms.forEach(form => {
	new AjaxForm(form);
})
